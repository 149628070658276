<template>
  <flux-input
    @update:model-value="setValue"
    :model-value="value?.toString()"
    type="date"
  />
</template>

<script lang="ts" setup>
const value = defineModel<Temporal.PlainDate>();

function setValue(newValue: string) {
  value.value = Temporal.PlainDate.from(newValue);
}
</script>
