import moment from "moment";
import { toRaw } from "vue";
import { isRef, Ref } from "vue";

type Writeable<T> = {
  -readonly [P in keyof T]: T[P];
};

export function clone<T>(obj: T): Writeable<T> {
  return structuredClone(deepToRaw(obj));
}

function deepToRaw<T>(obj: T | Ref<T>): T {
  if (isRef(obj)) {
    return deepToRaw(obj.value);
  }

  if (Array.isArray(obj)) {
    return obj.map((value) => deepToRaw(value)) as T;
  }

  if (obj === null) {
    return obj;
  }

  if (obj instanceof Date || obj instanceof Temporal.PlainDate) {
    return toRaw(obj);
  }

  if (obj instanceof moment) {
    throw new Error(
      "deepToRaw does not support Moment instances. Cloning the instance is not possible. Change this error message if you want to use deepToRaw for Moment without cloning, or preferrably, do not use Moment.",
    );
  }

  if (typeof obj === "object") {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, deepToRaw(value)]),
    ) as T;
  }

  return toRaw(obj);
}
